import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EmptyData from "../../Components/utils/empty";
import Loader from "../../Components/utils/loader";
import { displayAlert } from "../../helpers/alert";
import { baseURL } from "../../Config/config";
import CompaniesCards from "./CompaniesCards";

const Company = () => {
  const [companies, setCompanies] = React.useState(null);
  const [originalCompanies, setOriginalCompanies] = React.useState(null);
  const [data, setData] = React.useState();

  async function getAllCompany() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        setCompanies(response.data.data);
        setOriginalCompanies(response.data.data);
      })
      .catch((err) => {
        setData(<EmptyData />);
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  useEffect(() => {
    setData(<Loader />);
    getAllCompany();
  }, []);

  async function filter(text = "") {
    const items = originalCompanies;
    const results = items.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(text.toLowerCase())
    );
    setCompanies(results);
  }

  useEffect(() => {
    if (companies == null) return;
    if (companies.length > 0) {
      setData(<CompaniesCards companies={companies} />);
    } else {
      setData(<EmptyData />);
    }
  }, [companies]);

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-md-8 col-12">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>
                    <span className="me-5">Liste des Sociètés</span>{" "}
                    <small className=" ml-5">
                      <Link className="text-secondary" to={"/company/create"}>
                        <i className="fa fa-plus"></i> Ajouter
                      </Link>
                    </small>
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="page-header float-right">
                <div className="page-title">
                  <ol className="breadcrumb text-right">
                    <li>
                      <input
                        className="form-control"
                        placeholder="Chercher une Socièté"
                        onChange={(e) => {
                          filter(e.target.value);
                        }}
                      />
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="">{data}</div>
        </div>
      </div>
    </>
  );
};

export default Company;
