import React from "react";
import { RotatingLines } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="row align-items-center justify-content-center">
      <RotatingLines
        strokeColor="#007BEB"
        strokeWidth="5"
        animationDuration="1"
        width="60"
        visible={true}
        color="#007BEB"
      />
    </div>
  );
};

export default Loader;
