import React from "react";
import { Link } from "react-router-dom";

const CompaniesCards = ({ companies }) => {
  return (
    <>
      <div className="row">
        {companies &&
          companies.map((company, key) => {
            return (
              <div className="col-md-6 col-12" key={key}>
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <h4 className="text-uppercase col-8">{company.name}</h4>
                      <span className="col-4 float-right">
                        <Link to={`/company/${company.id}`}>
                          <i className="fa fa-pencil-square-o"></i>{" "}
                          <span className="d-md-none d-lg-inline">
                            Modifier
                          </span>
                        </Link>
                      </span>
                    </div>
                    <div className="row mb-2 mt-2">
                      <div className="col-md-6 col-lg-4">
                        <small>
                          Frais de gestion : {company.managementFees} %
                        </small>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <small>
                          Frais de mission : {company.missionFees} €
                        </small>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <small>SIRET : {company.siret}</small>
                      </div>
                    </div>
                    <p>
                      <small>{company.description}</small>
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div>
                          <small>
                            Charge Patronale : {company.employerTax} %
                          </small>
                        </div>
                        <div>
                          <small>
                            Charge Salarial : {company.employeeTax} %
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CompaniesCards;
