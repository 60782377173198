import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <aside id="left-panel" className="left-panel">
      <nav className="navbar navbar-expand-sm navbar-default">
        <div id="main-menu" className="main-menu collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li>
              <Link to="/users">
                {" "}
                <i className="menu-icon ti-user"></i>Utilisateurs{" "}
              </Link>
            </li>
            <li>
              <Link to="/companies">
                {" "}
                <i className="menu-icon fa fa-building-o"></i>Sociètés{" "}
              </Link>
            </li>
            <li>
              <Link to="/candidats">
                {" "}
                <i className="menu-icon ti-files"></i>Candidatures{" "}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </aside>
  );
};

export default Header;
