import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MultiFields from "../../Components/form/multiFields";
import { baseURL } from "../../Config/config";
import { displayAlert } from "../../helpers/alert";

const defaultCompany = {
  days: "",
  managementFees: "",
  missionFees: "",
  medicalFees: "",
  CSGImpo: "",
  CSGNotImpo: "",
  CRDS: "",
  APEC: "",
  CSA: "",
  SMIC: "",
  AVP: "",
  AVD: "",
  PASS: "",
  assMal: "",
  ACL: "",
  allcFam: "",
  accTrav: "",
  cotchom: "",
  AGS: "",
  CDS: "",
  ukCoef: "",
  netUKCoef: "",
  rtsCoef: "",
  employeeTax: "",
  employerTax: "",
  brutValue: "",
  siret: "",
  name: "",
  description: "",
  isJPG: "no",
  bonus: "[]",
};

const CreateCompany = () => {
  const navigate = useNavigate();

  const [company, setCompany] = useState(defaultCompany);
  const [bonus, setBonus] = useState([]);
  const [formErrors, setFormErrors] = useState(defaultCompany);

  async function save() {
    await setFormErrors(defaultCompany);
    company["bonus"] = JSON.stringify(bonus);
    await axios
      .post(`${baseURL}/companies/create`, company)
      .then((response) => {
        if (response.data.error) return;
        displayAlert("success", "Société Créée !", "");
        navigate("/companies");
      })
      .catch((err) => {
        setFormErrors({ ...defaultCompany, ...err.response.data });
        displayAlert("error", "Erreur est survenue !", "Veuiller réessayer !");
      });
  }

  return (
    <>
      <div className="breadcrumbs">
        <div className="breadcrumbs-inner">
          <div className="row m-0">
            <div className="col-sm-4">
              <div className="page-header float-left">
                <div className="page-title">
                  <h1>Créer une Socièté </h1>
                </div>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="animated">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="name" className=" form-control-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setCompany((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    id="name"
                    placeholder="Enter le nom"
                    className={
                      formErrors.name !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.name}</code>
                  </small>
                </div>
                <div className="row form-group col-md-6 col-12">
                  <div className="col col-md-12">
                    <label className=" form-control-label">Société JPG</label>
                  </div>
                  <div className="col col-md-12">
                    <div className="form-check-inline form-check">
                      <label htmlFor="isJPG1" className="form-check-label mx-2">
                        <input
                          type="radio"
                          onChange={(e) => {
                            setCompany((prevState) => ({
                              ...prevState,
                              isJPG: e.target.value,
                            }));
                          }}
                          id="isJPG1"
                          name="isJPG"
                          value="yes"
                          className="form-check-input"
                        />
                        Oui
                      </label>
                      <label htmlFor="isJPG2" className="form-check-label mx-2">
                        <input
                          type="radio"
                          onChange={(e) => {
                            setCompany((prevState) => ({
                              ...prevState,
                              isJPG: e.target.value,
                            }));
                          }}
                          id="isJPG2"
                          name="isJPG"
                          value="no"
                          checked
                          className="form-check-input"
                        />
                        Non
                      </label>
                    </div>
                  </div>
                  <small>
                    <code>{formErrors.isJPG != "no" && formErrors.isJPG}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="siret" className=" form-control-label">
                    SIRET
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setCompany((prevState) => ({
                        ...prevState,
                        siret: e.target.value,
                      }));
                    }}
                    id="siret"
                    placeholder="Enter le siret"
                    className={
                      formErrors.siret !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <small>
                    <code>{formErrors.siret}</code>
                  </small>
                </div>
                <div className="clearfix col-12"></div>
                <div className="form-group col-md-6 col-12">
                  <label htmlFor="description" className=" form-control-label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    onChange={(e) => {
                      setCompany((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }));
                    }}
                    id="description"
                    rows="5"
                    placeholder="Description ..."
                    className={
                      formErrors.description !== ""
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  ></textarea>
                  <small>
                    <code>{formErrors.description}</code>
                  </small>
                </div>
                <div className="form-group col-md-6 col-12">
                  <MultiFields bonus={bonus} setBonus={setBonus} />{" "}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="smicInput" className="form-label">
                      Smic
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="smicInput"
                        value={company.SMIC}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            SMIC: e.target.value,
                          }));
                        }}
                        placeholder="000.00"
                      />
                      <span className="input-group-text">€</span>
                    </div>
                    <small>
                      <code>{formErrors.SMIC}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="daysInput" className="form-label">
                      Nombre de jours du mois
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="daysInput"
                        value={company.days}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            days: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">Jours</span>
                    </div>
                    <small>
                      <code>{formErrors.days}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="managementFeesInput" className="form-label">
                      Frais de Gestion
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="managementFeesInput"
                        value={company.managementFees}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            managementFees: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.managementFees}</code>
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="brutValueInput" className="form-label">
                      Pourcentage Brut
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="brutValueInput"
                        value={company.brutValue}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            brutValue: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>
                        {formErrors.brutValue != 27 ? formErrors.brutValue : ""}
                      </code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="missionFeesInput" className="form-label">
                      frais de Mission
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="missionFeesInput"
                        value={company.missionFees}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            missionFees: e.target.value,
                          }));
                        }}
                        placeholder="000.00"
                      />
                      <span className="input-group-text">€</span>
                    </div>
                    <small>
                      <code>{formErrors.missionFees}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="ukCoefInput" className="form-label">
                      Bonus UK
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="ukCoefInput"
                        value={company.ukCoef}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            ukCoef: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <small>
                    <code>{formErrors.ukCoef}</code>
                  </small>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="rtsInput" className="form-label">
                      Retenue à la source
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="rtsInput"
                        value={company.rtsCoef}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            rtsCoef: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.rtsCoef}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="netUkCoefInput" className="form-label">
                      Coeffession Net UK
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="netUkCoefInput"
                        value={company.netUKCoef}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            netUKCoef: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.netUKCoef}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="employeeTaxInput" className="form-label">
                      TAX Salarié
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="employeeTaxInput"
                        value={company.employeeTax}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            employeeTax: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.employeeTax}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="employerTaxInput" className="form-label">
                      TAX Employeur
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="employerTaxInput"
                        value={company.employerTax}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            employerTax: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.employerTax}</code>
                    </small>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="medialFeesInput" className="form-label">
                      Frais complementaires santé
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="medialFeesInput"
                        value={company.medicalFees}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            medicalFees: e.target.value,
                          }));
                        }}
                        placeholder="000.00"
                      />
                      <span className="input-group-text">€</span>
                    </div>
                    <small>
                      <code>{formErrors.medicalFees}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="apecInput" className="form-label">
                      APEC
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="apecInput"
                        value={company.APEC}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            APEC: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.APEC}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="csaInput" className="form-label">
                      Contribution solidarité autonomie
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="csaInput"
                        value={company.CSA}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            CSA: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.CSA}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="cdsInput" className="form-label">
                      Contribution au dialogue Social
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="cdsInput"
                        value={company.CDS}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            CDS: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.CDS}</code>
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="avpInput" className="form-label">
                      Assurance Viellece Plafonnée
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="avpInput"
                        value={company.AVP}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            AVP: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.AVP}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="avdInput" className="form-label">
                      Assurance Viellece déplafonnée
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="avdInput"
                        value={company.AVD}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            AVD: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.AVD}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="assMalInput" className="form-label">
                      Assurance Maladie
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="assMalInput"
                        value={company.assMal}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            assMal: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.assMal}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="aclInput" className="form-label">
                      Autres Contribution par l&apos;employeur
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="aclInput"
                        value={company.ACL}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            ACL: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.ACL}</code>
                    </small>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="allcFamInput" className="form-label">
                      Allocation Familiale
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="allcFamInput"
                        value={company.allcFam}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            allcFam: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.allcFam}</code>
                    </small>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="accTravInput" className="form-label">
                      Accident de travail
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="accTravInput"
                        value={company.accTrav}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            accTrav: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.accTrav}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="cotchomInput" className="form-label">
                      Cotisation de Chômmage
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="cotchomInput"
                        value={company.cotchom}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            cotchom: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.cotchom}</code>
                    </small>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="agsInput" className="form-label">
                      Cotisation AGS
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="agsInput"
                        value={company.AGS}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            AGS: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.AGS}</code>
                    </small>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="csgImpoInput" className="form-label">
                      CSG Imposable
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="csgImpoInput"
                        value={company.CSGImpo}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            CSGImpo: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.CSGImpo}</code>
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="csgNotImpoInput" className="form-label">
                      CSG Non Imposable
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="csgNotImpoInput"
                        value={company.CSGNotImpo}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            CSGNotImpo: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.CSGNotImpo}</code>
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="crdsInput" className="form-label">
                      CRDS
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="crdsInput"
                        value={company.CRDS}
                        onChange={(e) => {
                          setCompany((prevState) => ({
                            ...prevState,
                            CRDS: e.target.value,
                          }));
                        }}
                        placeholder="00"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                    <small>
                      <code>{formErrors.CRDS}</code>
                    </small>
                  </div>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row-reverse text-right">
                <button
                  type="button"
                  onClick={save}
                  className="btn btn-primary btn-sm mx-2"
                >
                  Enregistrer
                </button>
                <Link
                  className="btn btn-link btn-sm text-secondary mx-2"
                  to={"/companies"}
                >
                  Annuler
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompany;
