import React from "react";

const CandidatsCards = ({ candidats }) => {
  return (
    <>
      <div className="row">
        {candidats &&
          candidats.map((candidat, key) => {
            return (
              <div className="col-md-6 col-12" key={key}>
                <div className="card">
                  <div className="card-header">
                    <h4 className="text-uppercase mb-2">{candidat.name}</h4>
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <small>Email : {candidat.email}</small>
                      </div>
                      <div className="col-md-12">
                        <small>Téléphone : {candidat.phone}</small>
                      </div>
                      <div className="col-md-12">
                        <small>Objet : {candidat.object}</small>
                      </div>
                      <div className="col-md-12">
                        <small>Message : {candidat.message}</small>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-lg-4 col-6">
                        <small>Montant : {candidat.amount}€</small>
                      </div>
                      <div className="col-lg-4 col-6">
                        <small>Frais facturés : {candidat.costs}€</small>
                      </div>
                      <div className="col-lg-4 col-6">
                        <small>Nombre de jours : {candidat.days_number}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CandidatsCards;
