import React, { useState } from "react";

const MultiFields = ({ bonus, setBonus }) => {
  const [fields, setFields] = useState([generateField()]);

  function generateField(key = 0) {
    return (
      <div className="row col-11">
        <div className="form-group col-md-6">
          <input
            type="text"
            onChange={(e) => {
              updateBonusName(key, e.target.value);
            }}
            placeholder="Titre de prime"
            className={"form-control"}
          />
        </div>
        <div className="form-group col-md-6">
          <input
            type="text"
            onChange={(e) => {
              updateBonusValue(key, e.target.value);
            }}
            placeholder="Valeur"
            className={"form-control"}
          />
        </div>

        <small>{/* <code>{formErrors.contracts}</code> */}</small>
      </div>
    );
  }

  function updateBonusName(key, name) {
    var bonusArray = bonus;
    if (bonusArray[key] === undefined) {
      bonusArray[key] = { name: name, value: "" };
    } else {
      bonusArray[key]["name"] = name;
    }
    setBonus([...bonusArray]);
  }

  function updateBonusValue(key, value) {
    var bonusArray = bonus;
    if (bonusArray[key] === undefined) {
      bonusArray[key] = { name: "", value: value };
    } else {
      bonusArray[key]["value"] = value;
    }
    setBonus([...bonusArray]);
  }

  function addField() {
    let array = fields;
    array.push(generateField(array.length));
    setFields([...array]);
  }

  function removeField(item, key) {
    item.parentNode.parentNode.remove();
    let array = bonus;
    array.splice(key, 1);
    setBonus([...array]);
  }

  return (
    <div className="col-12">
      <h5 className="mb-3">liste des Primes :</h5>
      <>
        {fields &&
          fields.map((item, key) => {
            return (
              <div key={key} className="row">
                {item}
                {key > 0 ? (
                  <div className="col-md-1">
                    <button
                      className="btn btn-danger"
                      onClick={(e) => removeField(e.currentTarget, key)}
                    >
                      <i className="menu-icon fa fa-remove"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </>
      <div className="form-group col-md-12 text-center">
        <button className="btn btn-success" onClick={addField}>
          Ajouter
        </button>
      </div>
    </div>
  );
};

export default MultiFields;
